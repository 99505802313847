import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { navigate } from "gatsby";
import Masonry from "react-masonry-css";
import {
  CommunityCardHolderDiv,
  CommunityCard,
  OverlayDiv,
  BackgroundOverlay,
} from "./elements";
import CloseButton from "./../icons/CloseButton";
import { H4Strong, H6Big, PH7 } from "./../Typography";
import MiscSocialSmallParticipant from "../icons/MiscSocialSmallParticipant";
import { SmallButtonParticipant } from "../ButtonsParticipants";
import { RelatedEventDiv } from "../../styles/event";
import EventList from "../../components/EventList";
import ArrowDown from "../icons/ArrowDown";

export const Overlay = ({
  communityMember,
  photoCoordinates,
  holderCoordinates,
  closeThis,
  randomNumber,
}) => {
  const participantLinks = {
    linkedin: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "LinkedIn"
    )?.value,
    twitter: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "Twitter"
    )?.value,
    facebook: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "Facebook"
    )?.value,
    instagram: communityMember.communityMemberContactDetails?.find(
      (item) => item.method === "Instagram"
    )?.value,
  };
  const myLeft = photoCoordinates?.x - holderCoordinates?.x - 50;
  const myTop = photoCoordinates?.top - holderCoordinates?.top - 88;
  const myColumn = Math.floor(myLeft / 315); // 0, 1, 2, or 3

  return (
    <OverlayDiv
      left={myLeft}
      top={myTop}
      myColumn={myColumn}
      backgroundColorDiv={randomNumber}
    >
      <div>
        <div id={"image"}>
          {communityMember.headerImage &&
          communityMember.headerImage.length &&
          communityMember.headerImage[0].localFile &&
          communityMember.headerImage[0].localFile.childImageSharp ? (
            <Img
              fixed={
                communityMember.headerImage[0].localFile.childImageSharp.fixed
              }
              style={{
                width: "100%",
                aspectRatio: "1/1",
                maxWidth: "259px",
                maxHeight: "259px",
              }}
            />
          ) : communityMember.headerImage.length &&
            communityMember.headerImage[0].localFile ? (
            <img src={communityMember.headerImage[0].localFile.publicURL} />
          ) : (
            <div className="placeholder" />
          )}
          {/* <Link to={`/community/${communityMember.communityMemberSlug}/`}>
              <SmallButtonParticipant color={"var(--red)"} backgroundColor={"transparent"}>
                Find out more
              </SmallButtonParticipant>
            </Link> */}
          <MiscSocialSmallParticipant
            color={randomNumber || "var(--black)"}
            participantLinks={participantLinks}
          />
        </div>
        {/* <div id={"image-contacts"}>         
        </div> */}
        <div
          id={"details"}
          dangerouslySetInnerHTML={{
            __html: communityMember.communityMemberDescription,
          }}
        >
          {/* <p>Hello ae</p> */}
        </div>
        <div id={"contacts"} className="contactdetails">
          <div>
            <H4Strong>TYPE</H4Strong>
            {communityMember?.participantType?.length ? (
              <p>{communityMember.participantType[0]}</p>
            ) : null}
          </div>
          <div>
            <H4Strong>HOURS</H4Strong>
            <p className="canwrap">
              {communityMember.communityMemberOpeningHours}
            </p>
          </div>
          <div>
            <H4Strong>Location</H4Strong>
            <p className="canwrap">
              {communityMember?.participantLocation?.length
                ? communityMember?.participantLocation[0]?.title
                : ""}
            </p>
            <SmallButtonParticipant
              onClick={() => {
                navigate(
                  `/programme/map?location=${encodeURI(
                    communityMember?.participantLocation?.length
                      ? communityMember?.participantLocation[0]?.title
                      : ""
                  )}`
                );
              }}
              color={"var(--black)"}
              backgroundColor={"transparent"}
              randomNumber={randomNumber}
            >
              View on map
            </SmallButtonParticipant>
          </div>
        </div>
      </div>
      {communityMember?.relatedEvents &&
        communityMember?.relatedEvents.length > 0 && (
          <RelatedEventDiv useBorder backgroundColorDiv={randomNumber}>
            <p>
              <span>Related Events</span> <ArrowDown />
            </p>
            <EventList
              useFullWidth
              events={
                communityMember?.relatedEvents.length < 4
                  ? communityMember?.relatedEvents
                  : communityMember?.relatedEvents.slice(0, 4)
              }
              numberColumns={2}
            />
          </RelatedEventDiv>
        )}

      <CloseButton
        color={"var(--black)"}
        hoverColor={"var(--white)"}
        onClick={closeThis}
      />
    </OverlayDiv>
  );
};

const CommunityCardHolder = ({
  cards,
  closeOverlay,
  randomArray,
  cardsOrigin,
  clickable,
}) => {
  const [currentlySelected, setCurrentlySelected] = React.useState(null);
  const [overlayOn, setOverlayOn] = React.useState(false);
  const [oldCloseOverlay, setOldCloseOverlay] = React.useState(false);
  const holderRef = React.useRef(null);

  React.useEffect(() => {
    if (closeOverlay !== oldCloseOverlay) {
      setOverlayOn(false);
      setOldCloseOverlay(closeOverlay);
    }
  }, [closeOverlay]);

  return (
    <CommunityCardHolderDiv ref={holderRef}>
      {cards.length ? (
        <Masonry
          breakpointCols={{ default: 3, 1270: 2, 950: 2, 635: 2 }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {cards.map((card, index) => {
            return (
              <CommunityCard
                borderTopColor={card.participantType[0]}
                H6Color={card.participantType[0]}
                randomNumber={randomArray[cardsOrigin.indexOf(card)]}
                key={index}
                clickable={clickable}
                onClick={(e) => {
                  if (clickable) {
                    e.preventDefault();
                    setCurrentlySelected(card);
                    setOverlayOn(true);
                  }
                }}
              >
                <a>
                  {card.headerImage.length &&
                  card.headerImage[0] &&
                  card.headerImage[0].localFile &&
                  card.headerImage[0].localFile.childImageSharp ? (
                    <div id={card.slug.replace(/\d/g, "")}>
                      <Img
                        fixed={
                          card.headerImage[0].localFile.childImageSharp.fixed
                        }
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  ) : card.headerImage.length &&
                    card.headerImage[0] &&
                    card.headerImage[0].localFile ? (
                    <img
                      src={card.headerImage[0].localFile.publicURL}
                      id={card.slug.replace(/\d/g, "")}
                    />
                  ) : (
                    <div
                      className="placeholder"
                      id={card.slug.replace(/\d/g, "")}
                    />
                  )}
                  <H6Big style={{ zIndex: 1 }}>{card.title}</H6Big>
                  {card?.participantType && card?.participantType?.length ? (
                    <PH7 style={{ zIndex: 1 }}>{card.participantType[0]}</PH7>
                  ) : null}
                </a>
                <BackgroundOverlay />
              </CommunityCard>
            );
          })}
        </Masonry>
      ) : (
        <p style={{ color: "var(--white)" }}>
          No participants shown! Try changing your filters?
        </p>
      )}

      {overlayOn ? (
        <Overlay
          communityMember={currentlySelected}
          photoCoordinates={holderRef.current
            .querySelector(`#${currentlySelected.slug.replace(/\d/g, "")}`)
            .getBoundingClientRect()}
          holderCoordinates={holderRef.current.getBoundingClientRect()}
          closeThis={() => {
            setOverlayOn(false);
          }}
          randomNumber={randomArray[cardsOrigin.indexOf(currentlySelected)]}
        />
      ) : null}
    </CommunityCardHolderDiv>
  );
};

export default CommunityCardHolder;

CommunityCardHolder.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      communityMemberSlug: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  clickable: PropTypes.bool,
};

CommunityCardHolder.defaultProps = {
  clickable: true,
};
