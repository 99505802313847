import styled from "styled-components";

// TODO: This is somhow deeply screwed.

export const CommunityCardHolderDiv = styled.div`
  padding: 0;
  /* padding: var(--insidePadding); */
  display: flex;
  flex-wrap: wrap;
  width: var(--width);
  margin: 50px auto 0;
  justify-content: center;
  position: relative;
  /* color: var(--white); */
  /* background-color: var(--background); */
  & .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -20px; /* gutter size offset */
    width: auto;
  }
  & .my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
    width: 315px !important;
    &:nth-child(1),
    &:nth-child(5) {
      margin-top: 75px;
    }
    &:nth-child(3),
    &:nth-child(7) {
      margin-top: 150px;
    }
    &:nth-child(4),
    &:nth-child(8) {
      margin-top: 115px;
    }
  }

  & .my-masonry-grid_column > div {
    margin-bottom: 30px;
  }
  & div.placeholder {
    height: 295px;
    background-color: var(--red);
  }
  @media (max-width: 767px) {
    margin: 36px 0 0 0 !important;
    width: 100% !important;
    padding: 0 20px;
    & > div:first-child {
      margin-left: 0;
      width: 100% !important;
      & > div:nth-of-type(1) {
        width: 50% !important;
        margin-right: 20px !important;
      }
      & > div:nth-of-type(2) {
        width: 50% !important;
      }
    }
    & .my-masonry-grid_column {
      padding-left: 0;
      &:nth-child(1),
      &:nth-child(5) {
        margin-top: 0;
      }
      &:nth-child(3),
      &:nth-child(7) {
        margin-top: 0;
      }
      &:nth-child(4),
      &:nth-child(8) {
        margin-top: 0;
      }
      & > div {
        margin-bottom: 12px;
      }
    }
  }
  @media (max-width: 576px) {
    & > div:first-child {
      width: 100% !important;
      margin-left: 0 !important;
    }
    & > div > div:nth-of-type(2) {
      margin-top: 36px;
    }
  }
`;

export const CommunityCard = styled.div`
  --culinary: #2fed1c;

  --cyborg: #ff644e;
  --childhood: #8e69ee;
  --nature: #ffc327;

  width: 295px;
  height: 305px;
  margin-bottom: 12px;
  border-top: 10px solid transparent;
  --borderTopColor: ${(props) => {
    if (props.borderTopColor === "Food") {
      return "var(--culinary)";
    } else {
      if (props.randomNumber === 1) {
        return "var(--cyborg)";
      } else if (props.randomNumber === 2) {
        return "var(--childhood)";
      } else if (props.randomNumber === 3) {
        return "var(--nature)";
      }
    }
  }};
  border-top-color: var(--borderTopColor);
  --H6Color: ${(props) => {
    if (props.H6Color === "Food") {
      return "var(--culinary)";
    } else {
      if (props.randomNumber === 1) {
        return "var(--cyborg)";
      } else if (props.randomNumber === 2) {
        return "var(--childhood)";
      } else if (props.randomNumber === 3) {
        return "var(--nature)";
      }
    }
  }};
  position: relative;
  cursor: ${(props) => (props.clickable ? "pointer" : "unset")};
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
    transition: all 0.3s;
  }
  & h6 {
    position: absolute;
    bottom: 47px;
    left: 20px;
    color: var(--H6Color);
    text-shadow: 0px 0.4px 0px var(--H6Color);
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & p {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: var(--white);
    text-shadow: 0px 0.4px 0px var(--white);
  }

  & > a {
    color: var(--black);
    & > div {
      /* background-color: var(--red); */
      width: 100%;
      /* min-height: 400px; */
      & > div {
        height: 295px !important;
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    height: 305px !important;
    width: 100% !important;
    & h5 {
      text-align: center;
    }
  }
  @media (max-width: 576px) {
    box-sizing: content-box;
    height: 157.5px !important;
    border-top-width: 6px !important;
    & > a > div {
      & > div:first-child {
        height: 157.5px !important;
      }
    }
    & > a {
      & > h6 {
        font-weight: 700;
        font-size: 15px;
        line-height: 140%;
        bottom: 32px;
        left: 12px;
      }
      & > p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        bottom: 12px;
        left: 12px;
      }
    }
  }
`;

export const OverlayDiv = styled.div`
  /**
* myColumn comes in as 0, 1, 2, or 3
*
* if it's 0 or 1, text block should be on right
* if it's 1 or 2, text block should be on the left
 */
  --yellow2: #ffc327;
  --purple2: #8e69ee;
  --backgroundColor: ${(props) => {
    if (props.backgroundColorDiv === 1) {
      return "var(--red)";
    } else if (props.backgroundColorDiv === 3) {
      return "var(--yellow2)";
    } else if (props.backgroundColorDiv === 2) {
      return "var(--purple2)";
    } else {
      return "var(--green)";
    }
  }};

  --borderTopDiv: ${(props) => {
    if (
      props.backgroundColorDiv === 1 ||
      props.backgroundColorDiv === 2 ||
      props.backgroundColorDiv === 3
    ) {
      return "var(--white)";
    } else {
      return "var(--black)";
    }
  }};
  --borderTopPicture: ${(props) => {
    if (props.backgroundColorDiv === 1) {
      return "var(--blue)";
    } else if (props.backgroundColorDiv === 3) {
      return "var(--green2)";
    } else if (props.backgroundColorDiv === 2) {
      return "var(--pink)";
    } else {
      return "var(--black)";
    }
  }};

  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1024px;
  overflow-y: auto;
  overflow-x: hidden;

  width: calc(var(--width) - 410px);
  z-index: 9;
  & > div:first-child {
    background-color: var(--backgroundColor);
    padding: 88px 50px 105px;
    border-top: 4px solid transparent;
    border-top-color: var(--borderTopDiv);
    display: flex;
    gap: 2%;
    flex-direction: row;
    & div:first-child {
      & div {
        margin-bottom: 20px;
      }
    }
    & div img {
      border-top: 10px solid transparent;
      border-top-color: var(--borderTopPicture);
    }
    > div#image {
      flex: 0 0 28%;
    }
    > div#details {
      flex: 0 0 40%;
    }
    > div#contacts {
      flex: 0 0 28%;
    }

    & > div#details {
      & p {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.32px;
        font-weight: 400;
        margin: 0;
        color: var(--borderTopDiv);
        & strong {
          color: var(--borderTopPicture);
        }
      }
    }
    & button {
      margin-top: 20px;
    }
  }

  & > div:nth-of-type(2) {
    background-color: var(--black);
    padding: 50px;
    /* width: 1024px; */
    & > p {
      line-height: 125%;
      letter-spacing: 0.01em;
    }
    & p div {
      & div {
        grid-template-columns: 400px 400px;
      }
    }
    & > svg {
      height: 10px;
    }
  }

  & > svg {
    position: absolute;
    top: 29px;
    right: 26px;
    cursor: pointer;
  }
  & .contactdetails {
    width: 100%;
    overflow-x: hidden;
    color: var(--borderTopDiv);
    & div {
      border-top: 2px solid var(--borderTopDiv);
      width: 100%;
      & h4 {
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 21px;
        line-height: 140%;
      }
      & p {
        max-width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        font-size: 19px;
        margin: 0 0 18px 0;
        &.canwrap {
          white-space: initial;
          overflow-x: initial;
        }
        a {
          color: var(--black);
        }
      }
    }
    /* & p {
      max-width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      &.canwrap {
        white-space: initial;
        overflow-x: initial;
      }
      a {
        color: var(--black);
      }
    } */
  }

  @media (max-width: 1439px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-width: 1024px;
    z-index: 999;
  }
  @media (max-width: 1023px) {
    & > div:nth-of-type(2) {
      padding: 36px 20px !important;
    }
    & > div {
      /* flex-direction: column !important; */
      & > div:first-of-type {
        margin-bottom: 25px;
      }
      & > div:nth-of-type(2) {
        & > p {
          line-height: 30px !important;
          letter-spacing: 0 !important;
          /* padding: 36px 20px !important; */
        }
        & > p + div {
          & > div {
            grid-template-columns: 400px !important;
          }
        }
      }
      & > div:nth-of-type(3) {
        & > div {
          & > h4,
          & > p {
            white-space: normal;
          }
        }
        & > div:nth-of-type(3) {
          & > button {
            padding: 0 10px !important;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    top: 0px !important;
    position: fixed !important;
    max-height: 100% !important;
    width: 100% !important;
    /* height: 100% !important; */
    & > div:first-child {
      height: 100% !important;
      padding: 82px 20px 36px !important;
      /* height: 100% !important; */
      overflow-y: auto;
      margin-right: 0 !important;
      flex-direction: column !important;
      & > div:nth-of-type(1) {
        display: flex !important;
        flex-direction: column !important;
        & > div:first-of-type {
          width: 335px !important;
          height: 335px !important;
          align-self: center;
        }
      }
      & > div:nth-of-type(2) {
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 25px !important;
        max-height: unset !important;
        overflow: unset !important;
        & > p {
          font-size: 24px !important;
          line-height: 30px !important;
          margin-bottom: 36px;
          /* padding: 36px 20px; */
        }
      }

      & > div:nth-of-type(3) {
        margin-left: 0px !important;
        overflow-x: visible !important;
        & > div > h4 {
          font-size: 21px !important;
        }
        & > div > p {
          font-size: 19px !important;
        }
        & > div:last-of-type {
          & > button {
            font-weight: 400 !important;
          }
        }
      }
    }
    & > div:nth-of-type(2) {
      & > p {
        margin-bottom: 36px !important;
      }
      & > div:nth-child(2) {
        grid-template-columns: 335px !important;
        & div:nth-child(1) > div {
          padding: 12px !important;
          flex-wrap: nowrap !important;
          justify-content: center !important;
          width: 335px !important;
          align-items: center !important;
          & > a > .gatsby-image-wrapper {
            margin-right: 12px;
          }
          /* &:nth-child(3) > a {
            margin-left: -27px !important;
          } */
        }
      }
    }
  }
  @media (max-width: 576px) {
    height: 100% !important;
    & > div:nth-of-type(2) {
      margin-top: 0px !important;
      & > p {
        font-weight: 400 !important;
        font-size: 24px !important;
        & > svg {
          height: 10px !important;
        }
      }
      padding: 36px 20px 20px !important;
    }
  }
`;

export const Scrim = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.125);
  background: #f1f2ef;
  background-blend-mode: multiply;
  transition: 0.5s;
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 62.2%,
    rgba(0, 0, 0, 0.65) 86.1%
  );
`;
